import React from "react";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { createRoot } from "react-dom/client";
import TagManager from "react-gtm-module";
import { GTM_KEY } from "./constants";

const tagManagerArgs = {
  gtmId: GTM_KEY,
};

TagManager.initialize(tagManagerArgs);


const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <React.StrictMode>
    <BrowserRouter
      getUserConfirmation={(message, callback) => {
        const allowTransition = window.confirm(message);
        callback(allowTransition);
      }}
    >
      <App />
    </BrowserRouter>
  </React.StrictMode>
);
reportWebVitals();
